import Pusher from 'pusher-js';

class PusherController
{

  /**
   * Private object to store pusher data
   * @type {{channel_name: string, app_key: string, auth_url: string}}
   */
  #pusher = {
    app_key: "",
    auth_url: "", // JSONp Authentication URL
    channel_name: ""
  };

  /**
   * PusherController Constructor require unique identifier of user to
   * bind private channel in coherence with WP4B HUB
   * @param user_uid
   * @param pusher_data
   */
  constructor( user_uid, pusher_data ) {

    if(!pusher_data || !pusher_data.app_key || !pusher_data.auth_url || !pusher_data.channel_name)
      throw new Error("No pusher data provided");
    if(!user_uid)
      throw new Error('No user UID provided in WP4B SSO Router JS');

    // Set user uid
    this.uid = user_uid;
    this.#pusher = pusher_data;

    // Set channel name
    this.channelName = this.getChannelName();

    this.init();
  }

  init() {
    this.setSocket();
    this.subscribeToChannel();
  }

  /**
   * Instantiate Pusher with env variables PUSHER_APP_KEY and PUSHER_JSONP_AUTH_URL
   * @throws Error if socket connection fails
   */
  setSocket() {
    let socket = new Pusher(this.#pusher.app_key, {
      authTransport: 'jsonp',
      authEndpoint: this.#pusher.auth_url,
      cluster: 'eu'
    });

    socket.connection.bind( 'error', function( err ) {
      throw new Error(err.message);
    });

    this.socket = socket;
  }

  /**
   * Method to disconnect from Pusher connection
   */
  disconnect() {
    this.socket.disconnect();
  }

  subscribeToChannel() {
    this.channel = this.socket.subscribe(this.channelName);
  }

  trigger(event, data) {
    return new Promise(resolve => resolve(this.channel.trigger(event, data)));
  }

  /**
   * Build channel name getting from process env the conventional name +
   * unique user identifier passed in Class parameters
   * @returns {string}
   */
  getChannelName() {
    return this.#pusher.channel_name + this.uid;
  }

}

export default PusherController;