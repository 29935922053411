//import Url from 'urlparse'

class UrlHelper
{
  /**
   * @type {string}
   */
  #url = "";

  /**
   * Static mime-types
   * @type {string[]}
   */
  #static_mime_types = [
    '.pdf', '.doc', '.docx', '.xls',
    '.xlsx', '.ppt', '.pptx', '.mp4',
    '.mov', '.mp3', '.waw', '.aiff',
    '.jpg', '.jpeg', '.png', '.zip',
    '.rar'
  ];

  #exceptionClasses = [
    'rollover-zoom'
  ];

  constructor( url ) {
    this.#url = url;
  }

  /**
   * Check if url is static getting head of link
   * If request fail anyway it resolve the promise
   * returning false
   * @returns {Promise<any>}
   */
  isStatic() {
    return this._multiIncludes(this.#url);
  }

  /**
   * Check if url is a mailto:{email}
   * @returns {boolean|*}
   */
  isMail() {
    return (this.#url.indexOf('mailto:') >= 0);
  }

  isJavascript() {
    return (this.#url.toLowerCase().substr(0,11) === 'javascript:');
  }

  /**
   * Check if url is empty
   * @returns {boolean}
   */
  isEmpty() {
    return (this.#url == "") || (this.#url == "#");
  }

  isAnchor() {
    return (this.#url.indexOf("#") != -1) &&
      (this.#url.replace(/#(.*)/g,'') == (location.href.replace(/#(.*)/g,'') + '/'));
  }

  /**
   * Check if url is the same as location
   * @returns {boolean}
   */
  isSameUrl() {
    return this.#url.replace(/#/g,'') == location.href.replace(/#/g,'');
  }

  /**
   * Check if there is a class that excepts from standard behaviour
   * @param node
   * @returns {boolean}
   */
  hasClassException( node ) {
    let result = false;

    this.#exceptionClasses.forEach(htmlClass => {
      if(node.classList.contains(htmlClass))
        result = true;
    });

    return result;
  }

  /**
   * Check if content-type is static
   * from array #static_mime_types
   * @param type {string}
   * @returns {boolean}
   */
  _multiIncludes( type ) {
    var re = new RegExp(this.#static_mime_types.join('|'));
    return re.test( type );
  }
}

export default UrlHelper;