import PubSub from 'pubsub-js';
import Url from './urlHelper';

class ClickController
{
  constructor() {
    this.init();
  }

  init() {
    this.registerClickEvents();
  }

  /**
   * Use Event delegation (https://humanwhocodes.com/blog/2009/06/30/event-delegation-in-javascript/)
   * to capture all click and findParent to find the <a> tag and publish event
   */
  registerClickEvents() {
    let that = this;
    document.body.onclick = function(e) {
      e = e || event;
      let a = that.getParents('a',e.target || e.srcElement);
      if (a){
        let url = new Url(a.href);
        console.debug('Open url',a.href,url)
        // Controllo che la url non sia vuota, uguale alla location, o una mail
        if(url.isEmpty() || url.isSameUrl() || url.isMail() || url.isJavascript())
          return;

        // Controllo che il link non abbia una classe che fa eccezione
        if(url.hasClassException(a))
          return;

        // Controllo che il link non sia un'ancora alla stessa pagina
        if(url.isAnchor()) {
          e.preventDefault();
          return;
        }

        // Controllo che la url non sia statica, altrimenti apro la url in una nuova finestra
        if(url.isStatic()) {
          e.preventDefault();
          window.open(a.href, '_blank');
          return;
        }
        e.preventDefault();
        PubSub.publish('URL_CHANGE', a.href);

      }
    }
  }

  /**
   *
   * @param selector
   * @param elem
   * @returns {null|*}
   */
  getParents( selector, elem ) {

  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Get the closest matching element
  for ( ; elem && elem !== document; elem = elem.parentNode ) {
    if ( elem.matches( selector ) ) return elem;
  }
  return null;

};


}

export default ClickController;