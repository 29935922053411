import ClickController from './lib/clickController';
import PusherController from './lib/pusher';

import PubSub from 'pubsub-js';

export class Router
{
  /**
   * Protected object to store Router parameters passed on instantiation
   * @type {{pusher: {}, app_url: string, user_uid: string}}
   */
  #params = {
    user_uid: "",
    pusher: {},
    app_url: ""
  };

  constructor( params ) {
    this.#params = params;
    this.init();
  }

  init() {
    new ClickController();
    this.pusher = new PusherController(this.#params.user_uid, this.#params.pusher);
    PubSub.subscribe('URL_CHANGE', this.handleChangeUrl);
  }

  handleChangeUrl = ( msg, url ) => {

    let destinationUrl = this.toAbs(url);

    if(!destinationUrl)
      throw new Error('No destination URL to evaluate');

    this.pusher.trigger('client-route', { destinationUrl: destinationUrl })

  };

  go = ( url ) => {

    let destinationUrl = this.toAbs(url);

    if(!destinationUrl)
      throw new Error('No destination URL to evaluate');

    this.pusher.trigger('client-route', { destinationUrl: destinationUrl })

  };

  toAbs( link ) {

    let host = window.location.origin;

    var lparts = link.split('/');
    if (/http:|https:|ftp:/.test(lparts[0])) {
      // already abs, return
      return link;
    }

    var i, hparts = host.split('/');
    if (hparts.length > 3) {
      hparts.pop(); // strip trailing thingie, either scriptname or blank
    }

    if (lparts[0] === '') { // like "/here/dude.png"
      host = hparts[0] + '//' + hparts[2];
      hparts = host.split('/'); // re-split host parts from scheme and domain only
      delete lparts[0];
    }

    for(i = 0; i < lparts.length; i++) {
      if (lparts[i] === '..') {
        // remove the previous dir level, if exists
        if (typeof lparts[i - 1] !== 'undefined') {
          delete lparts[i - 1];
        } else if (hparts.length > 3) { // at least leave scheme and domain
          hparts.pop(); // stip one dir off the host for each /../
        }
        delete lparts[i];
      }
      if(lparts[i] === '.') {
        delete lparts[i];
      }
    }

    // remove deleted
    var newlinkparts = [];
    for (i = 0; i < lparts.length; i++) {
      if (typeof lparts[i] !== 'undefined') {
        newlinkparts[newlinkparts.length] = lparts[i];
      }
    }

    return hparts.join('/') + '/' + newlinkparts.join('/');

  }

  static getDomain( url ) {
    const prefix = /^https?:\/\//;
    const domain = /^[^\/:]+/;
    // remove any prefix
    url = url.replace(prefix, "");
    // assume any URL that starts with a / is on the current page's domain
    if (url.charAt(0) === "/") {
      url = window.location.hostname + url;
    }
    // now extract just the domain
    let match = url.match(domain);
    if (match) {
      return(match[0]);
    }
    return(null);
  }
}
